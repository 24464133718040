import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { IoLogoVercel } from 'react-icons/all'
import BlockContent from '@sanity/block-content-to-react'
import { PageWrapper, ButtonComponentStyles } from '../styles/GenericStyles'
import SEO from '../components/SEO'

const Wrapper = styled(PageWrapper)`
  .container {
    border-left: 1px solid green;
    margin: 5em 0 0 5em;
    padding-left: 2em;
  }

  .darker-grey {
    color: var(--darker-grey);
  }

  @media (max-width: 768px) {
    .container {
      margin: 0;
      padding-left: 0.5em;
    }
  }
`

const ProjectHeaderStyles = styled.div`
  p,
  a {
    margin: 0.7em 0;
  }
  span {
    color: var(--lime);
  }
  button {
    margin-right: 2em;
    cursor: not-allowed;
  }

  .yellow {
    color: yellow;
  }
`

function ProjectHeader({
  props: { pageTitle, subTitle, technologies, projectUrl, introText, image },
}) {
  const headerImageStyle = {
    margin: '1em 0',
  }
  return (
    <>
      <SEO title="Project: GFG Testbox" />
      <ProjectHeaderStyles>
        <h1>{pageTitle}</h1>
        <span>{subTitle}</span>
        <p className="darker-grey">{technologies}</p>
        <ButtonComponentStyles disabled target="_blank" deg="rotate(0deg)">
          ⛔ sorry! code belongs to my client
        </ButtonComponentStyles>
        <ButtonComponentStyles
          deg="rotate(-5deg)"
          as="a"
          href={projectUrl}
          target="_blank"
        >
          <IoLogoVercel /> see the working project
        </ButtonComponentStyles>
        <p>{introText}</p>
        <p className="yellow">⚠️ This project is still work in progress</p>

        <Img fluid={image.asset.fluid} style={headerImageStyle} />
      </ProjectHeaderStyles>
    </>
  )
}

ProjectHeader.propTypes = {
  props: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    technologies: PropTypes.string.isRequired,
    projectUrl: PropTypes.string.isRequired,
    introText: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
}

const ProjectPresentationStyles = styled.div`
  margin: 1.5em 0;
  p {
    margin: 1em 0;
  }
  span {
    color: var(--lime);
  }

  li {
    margin-left: 1em;
    padding: 0.5em 0;
  }

  h2 {
    margin-top: 1em;
  }

  .image-container {
    margin: 1em 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
  }
  @media (max-width: 768px) {
    .image-container {
      grid-template-columns: 1fr;
    }
  }
`

const StyledImg = styled(Img)`
  transition: scale 500ms;
  &:hover {
    scale: 1.5;
    z-index: 40;
    transition: scale 500ms;
  }
`

function ProjectPresentation({
  props: { rawProblem, rawRole, applicationImages },
}) {
  return (
    <ProjectPresentationStyles>
      <h2>the problem</h2>
      <BlockContent blocks={rawProblem} />
      <h2>my responsibilities </h2>
      <BlockContent blocks={rawRole} />
      <div className="image-container">
        <StyledImg fluid={applicationImages[0].asset.fluid} />
        <StyledImg fluid={applicationImages[1].asset.fluid} />
      </div>
    </ProjectPresentationStyles>
  )
}

ProjectPresentation.propTypes = {
  props: PropTypes.shape({
    rawProblem: PropTypes.array.isRequired,
    rawRole: PropTypes.array.isRequired,
    applicationImages: PropTypes.array.isRequired,
  }),
}

const ProjectSolutionStyles = styled.div`
  margin: 1.5em 0;
  li {
    margin-left: 1em;
    padding: 0.5em 0;
  }

  h2 {
    margin-top: 1em;
  }
`

function ProjectSolution({ props: { rawChallenges, rawSolution } }) {
  return (
    <ProjectSolutionStyles>
      <h2>Challenges</h2>
      <BlockContent blocks={rawChallenges} />
      <h2>Strategies</h2>
      <BlockContent blocks={rawSolution} />
    </ProjectSolutionStyles>
  )
}

ProjectSolution.propTypes = {
  props: PropTypes.shape({
    rawChallenges: PropTypes.array.isRequired,
    rawSolution: PropTypes.array.isRequired,
  }),
}

const StyledClientImg = styled(Img)`
  height: 10em;
  width: 10em;
  object-fit: contain;
  border-radius: 50%;
  margin: 1em;
`

const StyledClientOpinion = styled.div`
  .opinion-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    .opinion-container {
      grid-template-columns: 1fr;
    }
  }

  .client {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .opinion {
    margin: 2em;
    background: blue;
    font-style: italic;
    padding: 0.8em;
    background: #222324;
    text-align: center;
  }
`

function ClientOpinion({
  props: { clientImage, clientName, clientTitle, clientOpinion },
}) {
  return (
    <StyledClientOpinion>
      <h2>My Client</h2>
      <div className="opinion-container">
        <div className="client">
          <StyledClientImg fluid={clientImage.asset.fluid} />
          <p>
            {clientName}, <br /> {clientTitle}
          </p>
        </div>
        <p className="opinion">{clientOpinion}</p>
      </div>
    </StyledClientOpinion>
  )
}

ClientOpinion.propTypes = {
  props: PropTypes.shape({
    clientImage: PropTypes.object.isRequired,
    clientName: PropTypes.string.isRequired,
    clientTitle: PropTypes.string.isRequired,
    clientOpinion: PropTypes.string.isRequired,
  }),
}

function testbox(props) {
  const data = props.data.allSanityProject.nodes[0]
  return (
    <Wrapper>
      <div className="container">
        <ProjectHeader
          props={{
            pageTitle: data.pageTitle,
            subTitle: data.subTitle,
            technologies: data.technologies,
            projectUrl: data.projectUrl,
            introText: data.introText,
            image: data.image,
          }}
        />
        <ProjectPresentation
          props={{
            rawProblem: data._rawProblem,
            rawRole: data._rawRole,
            applicationImages: data.applicationImages,
          }}
        />
        <ProjectSolution
          props={{
            rawChallenges: data._rawChallenges,
            rawSolution: data._rawSolution,
          }}
        />
        <ClientOpinion
          props={{
            clientImage: data.clientImage,
            clientName: data.clientName,
            clientTitle: data.clientTitle,
            clientOpinion: data.clientOpinion,
          }}
        />
      </div>
      <div>Hello</div>
    </Wrapper>
  )
}

export default testbox

export const query = graphql`
  query TestBoxQuery($slug: String) {
    allSanityProject(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        name
        projectUrl
        repoUrl
        pageTitle
        subTitle
        technologies
        introText
        _rawProblem
        _rawRole
        _rawChallenges
        _rawSolution
        clientOpinion
        clientName
        clientTitle
        image {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        applicationImages {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        clientImage {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
